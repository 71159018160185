//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import { profile_service } from "@/services";

export default {
  name: "partner-judges",
  metaInfo: {
    title: "Partner Judges",
  },
  components: {
    BaseLayout,
  },
  data() {
    return {
      show_alt_images: {},
      judges: [],
    };
  },
  mounted() {
    this.fetch_celebs();
  },
  methods: {
    fetch_celebs() {
      profile_service
        .get({ is_celeb: true })
        .then((data) => {
          this.judges.push(...data.results);
        })
        .catch((error) => {
          this.$q.notify({
            message: this.decode_error_message(error),
            color: "negative",
            icon: "mdi-close",
            textColor: "white",
          });
        });
    },
    set_to_show_alt(event) {
      this.show_alt_images[event.target.dataset.id] = true;
    },
  },
};
